<template>
    <Toast />
    <div class="web-camera-container">
        <div class="camera-button">
            <button
                type="button"
                class="button is-rounded p-button p-component"
                :class="{ 'is-primary' : !isCameraOpen, 'is-danger' : isCameraOpen}" @click="toggleCamera">
                <span v-if="!isCameraOpen">Open Camera</span>
                <span v-else>Close Camera</span>
            </button>
        </div>

        <div v-show="isCameraOpen && isLoading" class="camera-loading">
            <ul class="loader-circle">
              <li></li>
              <li></li>
              <li></li>
            </ul>
        </div>

        <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
            <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
            <video v-show="!isPhotoTaken" ref="camera" autoplay></video>
            <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas"></canvas>
            <cropper
                class="cropper"
                :src="imgDataUrlCam"
                :stencil-props="{
                  aspectRatio: 4/4
                }"
                @change="change"
              ></cropper>
        </div>

        <div v-if="isCameraOpen && !isLoading && !imgDataUrlCam" class="camera-shoot">
            <button type="button" class="button" @click="takePhoto">
                <i class="pi pi-camera"></i>
            </button>
        </div>
        <div v-if="imgDataUrlCam && isCameraOpen">
            <br>
            <Button label="Redo" icon="pi pi-undo" class="p-button-rounded p-button-outlined mr-2 mb-2" v-on:click="redo"></Button>
            <br>
        </div>

        <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
            <Button label="Upload" icon="pi pi-upload" class="w-full" v-on:click="downloadImage"></Button>
        </div>

        <Divider layout="horizontal" align="center">
            <b>Or upload a picture directly</b>
        </Divider>

        <cropper
            class="cropper"
            :src="imgDataUrl"
            :stencil-props="{
              aspectRatio: 1/1
            }"
            @change="change"
          ></cropper>

        <div class="justify-center">
            <!--<FileUpload mode="basic" @select="onSelect" @uploader="myUploader" :customUpload="true" name="file[]" url="http://127.0.0.1:5105/accounts/upload" accept="image/*" :maxFileSize="3000000" :auto="true" @upload="onUpload" />-->
            <FileUpload ref="fileuploader" mode="basic" @select="onSelect" @uploader="myUploader" :customUpload="true" name="file[]" url="http://127.0.0.1:5105/accounts/upload" accept="image/*" :maxFileSize="3000000" @upload="onUpload" />
        </div>

        <div v-if="showPreview" class="flex justify-center" style="width:100%;flex-direction: column; align-items: center;">
        <Divider layout="horizontal" align="center">
            <b>Your current picture</b>
        </Divider>

        <Image
            :src="newImgDataUrl"
            :preview=true
        />
        </div>

    </div>
</template>

<script>
import { WebCam } from "vue-web-cam";
import UserService from '../service/UserService';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
    components: {
        Cropper
    },
    data() {
        return {
            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
			imgDataUrlCam: '',
			imgDataUrl: '',
			newImgDataUrl: '',
            showPreview: false
        }
    },
    userService: null,
    created() {
        this.userService = new UserService();
    },
    methods: {

        onSelect(evt) {
            this.showPreview = false;
            console.log(evt.files);
            console.log(evt.files[0].objectURL);
            this.imgDataUrl = evt.files[0].objectURL;
            this.newImgDataUrl = evt.files[0].objectURL;
            setTimeout(() => {
                document.querySelector(".p-button-label").textContent = "Upload";
            }, 100);
        },
        change({coordinates, canvas}) {
            console.log(coordinates, canvas);
            this.newImgDataUrl = canvas.toDataURL('image/jpeg', 0.7);
        },


        onUpload() {
            this.$toast.add({severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 5000});
        },

        toggleCamera() {
          if(this.isCameraOpen) {
            this.isCameraOpen = false;
            this.isPhotoTaken = false;
            this.isShotPhoto = false;
            this.stopCameraStream();

            this.imgDataUrlCam = '';

          } else {
            this.isCameraOpen = true;
            this.createCameraElement();
          }
        },
        
        createCameraElement() {
          this.isLoading = true;
          
          const constraints = (window.constraints = {
            audio: false,
            video: true
          });

          navigator.mediaDevices
            .getUserMedia(constraints)
            .then(stream => {
              this.isLoading = false;
              this.$refs.camera.srcObject = stream;
          }).catch(error => {
            this.isLoading = false;
            alert("No browser support or error.");
          });
        },
        
        stopCameraStream() {
          let tracks = this.$refs.camera.srcObject.getTracks();

          tracks.forEach(track => {
            track.stop();
          });
        },

        dataURItoBlob(dataURI) {
          // convert base64/URLEncoded data component to raw binary data held in a string
          var byteString;
          if (dataURI.split(',')[0].indexOf('base64') >= 0)
              byteString = atob(dataURI.split(',')[1]);
          else
              byteString = unescape(dataURI.split(',')[1]);

          // separate out the mime component
          var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

          // write the bytes of the string to a typed array
          var ia = new Uint8Array(byteString.length);
          for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
          }

          return new Blob([ia], {type:mimeString});
        },
        
        takePhoto() {
          if(!this.isPhotoTaken) {
            this.isShotPhoto = true;

            const FLASH_TIMEOUT = 50;

            setTimeout(() => {
              this.isShotPhoto = false;
            }, FLASH_TIMEOUT);
          }
          
          this.isPhotoTaken = !this.isPhotoTaken;

          var width = document.querySelector("video").offsetWidth;
          var height = document.querySelector("video").offsetHeight;

          this.$refs.canvas.width = width;
          this.$refs.canvas.height = height;

          const context = this.$refs.canvas.getContext('2d');
          context.drawImage(this.$refs.camera, 0, 0, width, height);

          // this.upload();
          this.imgDataUrlCam = document.getElementById("photoTaken").toDataURL('image/jpeg', 0.7);
          setTimeout(() => {
            document.querySelector("#photoTaken").style.display = 'none';
            this.stopCameraStream();
          }, 50);
        },

        redo() {
          this.imgDataUrlCam = '';
          document.querySelector("#photoTaken").style.display = 'block';
          this.toggleCamera();
          this.toggleCamera();
        },
        
        upload() {
            this.imgDataUrlCam = '';
            console.log("Uploading..");
            // var dataurl = document.getElementById("photoTaken").toDataURL('image/jpeg', 0.8);
            var dataurl = this.newImgDataUrl;
            var blob = this.dataURItoBlob(dataurl);
            this.newImgDataUrl = dataurl;
            var fd = new FormData();
            fd.append("file", blob, "thumb.jpg");
            this.userService.upload(fd).then(() => this.onUpload());
        },

        downloadImage(evt) {
            // const download = document.getElementById("downloadPhoto");
            // const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
            // download.setAttribute("href", canvas);
            evt.preventDefault();
            this.upload();
            this.toggleCamera();
            this.showPreview = true;
        },

        myUploader(event) {
            var fd = new FormData();
            // fd.append("file", event.files[0], "thumb.jpg");
            fd.append("file", this.dataURItoBlob(this.newImgDataUrl), "thumb.jpg");
            this.userService.upload(fd).then(() => this.onUpload());
            this.$refs.fileuploader.clear();
            setTimeout(() => {
                document.querySelector(".p-button-label").textContent = "Choose new picture";
                this.imgDataUrl = '';
                this.showPreview = true;
            }, 100);
        }
    }
}
</script>
<style scoped lang="scss">
video {
    width: 100%;
    height: auto;
    max-width: 450px;
}
canvas {
    width: 100%;
    height: auto;
    max-width: 450px;
}

.web-camera-container {
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;

  
  .camera-button {
    margin-bottom: 2rem;
  }
  
  .camera-box {    
    .camera-shutter {
      opacity: 0;
      width: 100%;
      max-width: 450px;
      /* height: 337.5px; */
      height: auto;
      background-color: #fff;
      position: absolute;
      
      &.flash {
        opacity: 1;
      }
    }
  }
  
  .camera-shoot {
    margin: 1rem 0;
    
    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      
      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }
  
  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;
    
    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }
    
    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 43%;
      left: 99%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;
      
      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;
        
        &:nth-child(2) {
          animation-delay: .2s;
        }
        
        &:nth-child(3) {
          animation-delay: .4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1
    }
    50% {
      opacity: .4
    }
    100% {
      opacity: 1
    }
  }
}
.completion-main {
    flex-direction: column;
}
</style>
<style>
.vue-advanced-cropper__background, .vue-advanced-cropper__foreground {
    background: white!important;
}
/*.vue-simple-handler {
    background: gray!important;
}*/
.vue-simple-handler {
    border: 1px solid gray!important;
}
.p-image img {
    max-width: 100%;
}
</style>
